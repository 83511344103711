<template>
  <div class="row mx-0 mt-4 justify-content-center">
    <div class="col-12 mb-3 text-center medium bigger_font">
      <span>Donation Amount: <b>{{total}}</b></span>
    </div>
    <div class="col-12 mb-3 text-center medium">
      I would like to make this payment:
    </div>
    <div class="col-10 col-md-8 mb-2">
      <div class="row mx-0 justify-content-center">
        <div class="col-11 mb-3 text-capitalize" v-for="frequency, key in frequencyOptions" :key="key">
          <Button :color="{green_light: frequency.save === frequencyValue, green: frequency.save !== frequencyValue}" width="100" :btnText="frequency.display" @buttonClicked="setFrequency(frequency.save)"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue'))
  },
  props: ['frequency', 'total'],
  emits: ['update:frequency'],
  name: 'Recurring FrequencySection',
  data () {
    return {
      frequencyOptions: [
        {
          display: 'Daily',
          save: 'DAILY'
        },
        {
          display: 'Weekly',
          save: 'WEEKLY'
        },
        {
          display: 'Monthly',
          save: 'MONTHLY'
        }
      ]
    }
  },
  computed: {
    frequencyValue: {
      get () {
        return this.frequency
      },
      set (val) {
        this.$emit('update:frequency', val)
      }
    }
  },
  methods: {
    setFrequency (val) {
      this.frequencyValue = val
    }
  }
}
</script>
<style scoped>
  .bigger_font {
    font-size: 20px;
  }
</style>
